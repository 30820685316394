






































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AccountServicePage extends Vue {
  step = 1

  nextStep() {
    this.step++;
  }

  backStep(step) {
    if (this.step < step) {
      return;
    }

    this.step = step;
  }
}
